.btn-outline-primary{
    color: #fed136;
    border-color: #fed136;
}

.btn-outline-primary:hover{
    background-color: #fed136;
    border-color: #fed136;
}

.btn-outline-primary:active, btn-outline-primary:not(:disabled):not(.disabled):active{
    background-color: #fed136 !important;
    border-color: #fed136 !important;
}

.btn:focus{
    box-shadow: 0 0 0 0.2rem rgba(254,209,54,.5) !important;
}

.form-control:focus{
    border-color: rgba(254,209,54,.5) !important;
    box-shadow: 0 0 0 0.2rem rgba(254,209,54,.5) !important;
}

body{
	--primary: #fed136;
	--secondary: #212529;
}
