
.btn-outline-primary{
    color: #fed136;
    border-color: #fed136;
}

.btn-outline-primary:hover{
    background-color: #fed136;
    border-color: #fed136;
}

.btn-outline-primary:active, btn-outline-primary:not(:disabled):not(.disabled):active{
    background-color: #fed136 !important;
    border-color: #fed136 !important;
}

.btn:focus{
    box-shadow: 0 0 0 0.2rem rgba(254,209,54,.5) !important;
}

.form-control:focus{
    border-color: rgba(254,209,54,.5) !important;
    box-shadow: 0 0 0 0.2rem rgba(254,209,54,.5) !important;
}

body{
	--primary: #fed136;
	--secondary: #212529;
}

footer{
    background: #212529;
    color: white;
}
.masthead{
    height: 100vh;
}

header.masthead {
    min-height: 42em;
}

.intro-avatar{
    height: 10em;
    border: 1px solid #FFFFFF;
    background-color: #212529;
}

.intro-text{
    padding-top: 20% !important;
    text-shadow: 0px 0px 20px #212529;
}

.intro-button-container{
    position: absolute;
    bottom: 1em;
    text-align: center;
    width: 100vw;
    left: 0;
}

.intro-button, .intro-button:focus{
    font-size: 3em;
    padding-top: 0;
    padding-bottom: 0;
    border-width: 1.5px;
    box-shadow: 0 0 50px #212529 !important;
    background: rgb(33,37,41, .2);
}

.custom-link-hovering:hover {
    text-decoration: none;
    color: #fed136;
    cursor: pointer;
    transition: all 500ms ease;
}

.custom-link-hovering {
    color: rgb(33, 37, 41);
    transition: all 500ms ease;
}
.timeline-appender > li:first-child {
    padding-top: 100px;
}
.portfolio-modal{
  margin: 1rem;
  max-width: 100vw;
}

div.lightbox-wrapper{
  margin-bottom: 30px;
}
/* .portfolio-modal .modal-content img{
    margin-bottom: 0;
} */

.img-clickable {
    cursor: pointer;
}

section#contact .form-group textarea.form-control{
    height: 339px;
}

section#contact {
    min-height: 100vh;
}

.publication-item {
    padding: 0.5em;
}

.publication-content {
    position: relative;
    padding: 25px;
    text-align: center;
}

.publication-link {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    cursor: pointer;
}
.publication-link:hover {
    text-decoration: none;
}

.publication-hover{
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    padding: 0px;
    position: absolute;
    background-color: rgba(254,209,54,.9);
    transition: all .5s ease;
    opacity: 0;
}

.publication-hover:hover{
    opacity: 1;
}
.publication-item {
    padding: 0.5em;
}

.publication-content {
    position: relative;
    padding: 25px;
    text-align: center;
}

.publication-link {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    cursor: pointer;
}
.publication-link:hover {
    text-decoration: none;
}

.publication-hover{
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    padding: 0px;
    position: absolute;
    background-color: rgba(254,209,54,.9);
    transition: all .5s ease;
    opacity: 0;
}

.publication-hover:hover{
    opacity: 1;
}
