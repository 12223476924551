.custom-link-hovering:hover {
    text-decoration: none;
    color: #fed136;
    cursor: pointer;

    -webkit-transition: all 500ms ease-in;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

.custom-link-hovering {
    color: rgb(33, 37, 41);

    -webkit-transition: all 500ms ease-in;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}