.masthead{
    height: 100vh;
}

header.masthead {
    min-height: 42em;
}

.intro-avatar{
    height: 10em;
    border: 1px solid #FFFFFF;
    background-color: #212529;
}

.intro-text{
    padding-top: 20% !important;
    text-shadow: 0px 0px 20px #212529;
}

.intro-button-container{
    position: absolute;
    bottom: 1em;
    text-align: center;
    width: 100vw;
    left: 0;
}

.intro-button, .intro-button:focus{
    font-size: 3em;
    padding-top: 0;
    padding-bottom: 0;
    border-width: 1.5px;
    box-shadow: 0 0 50px #212529 !important;
    background: rgb(33,37,41, .2);
}
