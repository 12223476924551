.publication-item {
    padding: 0.5em;
}

.publication-content {
    position: relative;
    padding: 25px;
    text-align: center;
}

.publication-link {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    cursor: pointer;
}
.publication-link:hover {
    text-decoration: none;
}

.publication-hover{
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    padding: 0px;
    position: absolute;
    background-color: rgba(254,209,54,.9);
    transition: all .5s ease;
    opacity: 0;
}

.publication-hover:hover{
    opacity: 1;
}